import { useContext } from 'react';
import { EstablishmentContext } from '../../../../../context/EstablishmentContext';

const useClearValues = ({
  permissao,
  setValue,
  handleSetFilter,
  watch,
  loadReportsTrackingHpv,
  setTableData,
  setUsePayloadFromCharts,
  showTableData,
  setShowTableData,
  clearErrors,
  setEstadosSelected,
  setMunicipiosSelected,
  setEstabelecimentosSelected,
  setAcsSelected,
  setListFilterToShow,
  listFilter,
}) => {
  const fieldsToClearWhenIsPadrao: any[] = [
    'acs',
    'idade_inicio',
    'idade_final',
    'rastreio',
    'has_teste',
    'has_procedimento',
    'periodo_inicio',
    'periodo_fim',
    'search',
    'prioridade',
  ];

  const fieldsToClearWhenIsMunicipal = [
    'estabelecimento_saude',
    ...fieldsToClearWhenIsPadrao,
  ];

  const fieldsToClearWhenEstadual = [
    'municipio',
    ...fieldsToClearWhenIsMunicipal,
  ];

  const fieldsToClearWhenSuperAdmin = ['estado', ...fieldsToClearWhenEstadual];

  const { currentEstablishment } = useContext(EstablishmentContext);

  const clearValues = ({
    setPeriod,
    setValueInputPeriod,
    setValuePeriodoModal,
    setValueInputQuadrimestreSemestre,
    setYear,
    setSelectedInicialAge,
    setSelectedFinalAge,
    setSelectedFaixa,
    setSelectedPrioridade,
    setSelectedMeio,
    setSelectedRealizacaoTeste,
  }) => {
    setPeriod(null);
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
    setYear(null);

    setSelectedInicialAge([]);
    setSelectedFinalAge([]);
    setSelectedFaixa([]);
    setSelectedPrioridade([]);
    setSelectedMeio([]);
    setSelectedRealizacaoTeste([]);

    clearErrors([]);
    setTableData([]);
    setShowTableData(false);
    if (permissao === 'isPadrao') {
      fieldsToClearWhenIsPadrao.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      setListFilterToShow(listFilter);

      // const payload = {
      //   estado: currentEstablishment.endereco.municipio.estado.nome,
      //   municipio: currentEstablishment.endereco.municipio.nome,
      //   estabelecimentoId: currentEstablishment.id,
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isMunicipal') {
      fieldsToClearWhenIsMunicipal.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      setEstabelecimentosSelected([]);
      setListFilterToShow(listFilter);

      // const payload = {
      //   estado: currentEstablishment.endereco.municipio.estado.nome,
      //   municipio: currentEstablishment.endereco.municipio.nome,
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isEstadual') {
      fieldsToClearWhenEstadual.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setListFilterToShow(listFilter);

      // const payload = {
      //   estado: currentEstablishment.endereco.municipio.estado.nome,
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }

    if (permissao === 'isSuperAdmin') {
      fieldsToClearWhenSuperAdmin.forEach((field) => {
        setValue(field, '');
        handleSetFilter('', field);
      });

      setEstabelecimentosSelected([]);
      setMunicipiosSelected([]);
      setEstadosSelected([]);
      setListFilterToShow([]);

      // const payload = {
      //   size: watch().size,
      //   page: watch().page,
      // };

      // if (showTableData) {
      //   loadReportsHpv(payload);
      // }
    }
  };

  return { clearValues };
};

export default useClearValues;

// const clearValues = () => {
//   setShowTableData(false);
//   clearErrors();
//   setUsePayloadFromCharts(false);
//   setTableData([]);

//   if (permissao === 'isPadrao') {
//     fieldsToClearWhenIsPadrao.forEach((field) => {
//       setValue(field, null);
//       handleSetFilter('', field);
//     });

//     const payload = {
//       estado: currentEstablishment.endereco.municipio.estado.nome,
//       municipio: currentEstablishment.endereco.municipio.nome,
//       estabelecimentoId: currentEstablishment.id,
//       size: watch().size,
//       page: watch().page,
//     };
//   }

//   if (permissao === 'isMunicipal') {
//     fieldsToClearWhenIsMunicipal.forEach((field) => {
//       setValue(field, '');
//       handleSetFilter('', field);
//     });

//     setEstabelecimentosSelected([]);

//     const payload = {
//       estado: currentEstablishment.endereco.municipio.estado.nome,
//       municipio: currentEstablishment.endereco.municipio.nome,
//       size: watch().size,
//       page: watch().page,
//     };
//   }

//   if (permissao === 'isEstadual') {
//     fieldsToClearWhenEstadual.forEach((field) => {
//       setValue(field, '');
//       handleSetFilter('', field);
//     });

//     setEstabelecimentosSelected([]);
//     setMunicipiosSelected([]);

//     const payload = {
//       estado: currentEstablishment.endereco.municipio.estado.nome,
//       size: watch().size,
//       page: watch().page,
//     };
//   }

//   if (permissao === 'isSuperAdmin') {
//     fieldsToClearWhenSuperAdmin.forEach((field) => {
//       setValue(field, '');
//       handleSetFilter('', field);
//     });

//     setEstadosSelected([]);
//     setEstabelecimentosSelected([]);
//     setMunicipiosSelected([]);

//     const payload = {
//       size: watch().size,
//       page: watch().page,
//     };
//   }
// };
