/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../../components/StyledAsteriskTextField/styles';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { getEstados } from '../../../../../lib/getEstados';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';
import api from '../../../../../api';
import MultipleFilters from '../../../../../components/MultipleFilters';
import useStatesFields from '../../../../../components/MultipleFilters/hooks/useStatesFields';
import { getUserToken } from '../../../../../lib/auth';
import useGetDetailsFilters from '../../../../Reports/ReportsHpv/FiltersReportsHpv/hooks/useGetDetailsFilters';

const FiltersVisaoGeral = ({
  control,
  setPayload,
  payload,
  getAllCharts,
  setListFilter,
  initialStateListFilter,
  setShowListFilter,
  listFilter,
  estabelecimentosSelected,
  estadosSelected,
  municipiosSelected,

  setEstabelecimentosSelected,
  setEstadosSelected,
  setMunicipiosSelected,
  valueInputPeriod,
  setValueInputPeriod,
  setPeriodToDisplay,
}) => {
  const today = moment().format('YYYY-MM-DD');
  const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [period, setPeriod] = useState<any>({});
  const [year, setYear] = useState<any>('');
  const [endDayAndMonth, setEndDayAndMonth] = useState<any>();
  const [initialDayAndMonth, setInitialDayAndMonth] = useState<any>();
  const [valuePeriodoModal, setValuePeriodoModal] = useState<any>();
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();

  const [dataFaixa, setDataFaixa] = useState<any>([]);

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setSearchAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    setDisableInputEstado,
    disableInputEstado,

    errorsFields,
    setErrorsFields,
  } = useStatesFields();

  const optionsPeriodo = [
    { label: 'Todos', value: 'all' },

    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];

  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };
  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-04-01');
    setValueInputQuadrimestreSemestre('1º Quadrimestre');
    setPeriod(undefined);
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    setValueInputQuadrimestreSemestre('1º Semestre');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-06-01');
    setPeriod(undefined);
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-12-01');

    setPeriod(undefined);
  };

  const setValuesAllPeriodSelected = () => {
    setValueInputPeriod('Todos');

    setPeriod({ periodo_inicio: '2022-01-01' });
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      setPeriod(undefined);
      setYear(undefined);
      setValueInputPeriod(null);
    }
    switch (newValue?.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();

        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      case 'all':
        setValuesAllPeriodSelected();
        break;
      default:
        break;
    }
  };

  const handleClearFilter = () => {
    setPeriod(null);
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
    setYear(null);

    setPeriodToDisplay('Todos os períodos');

    if (!isPadrao) {
      setEstabelecimentosSelected([]);
    }

    if (!isMunicipal && !isPadrao) {
      setMunicipiosSelected([]);
    }

    if (!isMunicipal && !isPadrao && !isEstadual) {
      setEstadosSelected([]);
    }
    setSearchEquipes('');
    setSearchEstabelecimentos('');
    setSearchEstado('');
    setSearchMunicipio('');

    initialStateListFilter.forEach((input) => {
      handleSetFilter(null, input.inputName);
    });

    setShowListFilter([]);

    getAllCharts({ cleared: false });
  };

  const userToken = getUserToken();
  const isPadrao = userToken.permissao_atual.id === 1;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isEstadual = userToken.permissao_atual.id === 4;

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (valueInputPeriod === 'Quadrimestral') {
      setValueInputQuadrimestreSemestre('1º Quadrimestre');
    }

    if (valueInputPeriod === 'Semestral') {
      setValueInputQuadrimestreSemestre('1º Semestre');
    }
    if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_fim: `${newValue}-12-31`,
        periodo_inicio: `${newValue}-01-01`,
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    switch (newValue) {
      case '1º Semestre':
        setPeriod({
          periodo_fim: `${year}-06-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Semestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-06-01`,
        });
        break;
      case '1º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-05-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-09-01`,
          periodo_inicio: `${year}-05-01`,
        });
        break;
      case '3º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-12-31`,
          periodo_inicio: `${year}-09-01`,
        });
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setPeriod(null);
    // handleSetFilter(null, 'periodo');
    setOpenModalPeriodo(false);
    setPeriod(null);
    setYear(null);
    setValueInputPeriod(null);
    setEndDayAndMonth(null);
    setInitialDayAndMonth(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
  };

  const handleSelectMultiplesFields = ({
    value,
    setState,
    state,
    paramToSave,
  }) => {
    const isSelected = state?.find(
      (itemSaved) => itemSaved === value[paramToSave]
    );

    if (isSelected) {
      setState((prev) => {
        return prev.filter((itemSaved) => itemSaved !== value[paramToSave]);
      });
    } else {
      setState((prev) => {
        return [...prev, value[paramToSave]];
      });
    }
  };

  const handleSetFilter = (newValue: any, inputName: string) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  const handleSetFiltersInMultiplesInputs = ({
    value,
    fieldName,
    arrayValues,
  }) => {
    const convertedFieldNames = {
      estado: 'Estados',
      municipio: 'Municípios',
      estabelecimento: 'Estabelecimentos',
      equipe: 'Equipes',
    };
    if (arrayValues.length > 1) {
      handleSetFilter(`${convertedFieldNames[fieldName]}: Vários`, fieldName);
    } else {
      handleSetFilter(`${convertedFieldNames[fieldName]}: ${value}`, fieldName);
    }
  };

  useEffect(() => {
    if (estadosSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: estadosSelected[0] ?? '',
        fieldName: 'estado',
        arrayValues: estadosSelected,
      });
      setDisabledMunicipioInput(isPadrao || isMunicipal ? true : false);
    } else {
      setDisabledMunicipioInput(true);
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
      handleSetFilter(null, 'estado');
    }

    if (estadosSelected.length > 1 || estadosSelected.length === 0) {
      if (!isPadrao) {
        setEstabelecimentosSelected([]);
      }
      setMunicipiosSelected([]);
      setEquipesSelected([]);
      setDisabledMunicipioInput(true);
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
    } else {
      setDisabledMunicipioInput(isPadrao || isMunicipal ? true : false);
    }
  }, [estadosSelected]);

  useEffect(() => {
    if (!isPadrao) {
      setEstabelecimentosSelected([]);
    }
    if (municipiosSelected.length > 0) {
      const municipioNome = optionsMunicipios?.find(
        (municipio) => municipio.id === municipiosSelected[0]
      )?.nome;
      handleSetFiltersInMultiplesInputs({
        value: municipioNome ?? '',
        fieldName: 'municipio',
        arrayValues: municipiosSelected,
      });
      setDisabledEstabelecimentoInput(isPadrao || isMunicipal ? true : false);
    } else {
      setDisabledEstabelecimentoInput(true);
      setDisableEquipeInput(true);
      handleSetFilter(null, 'municipio');
    }

    if (municipiosSelected.length > 1 || municipiosSelected.length === 0) {
      setDisabledEstabelecimentoInput(true);
    } else {
      setDisabledEstabelecimentoInput(isPadrao || isMunicipal ? true : false);
    }
  }, [municipiosSelected]);
  useEffect(() => {
    const estabelecimentoNome =
      optionsEstabelecimentos?.find(
        (item) => estabelecimentosSelected[0] === item.id
      )?.nome ?? '';

    if (estabelecimentosSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: estabelecimentoNome,
        fieldName: 'estabelecimento',
        arrayValues: estabelecimentosSelected,
      });
      setDisableEquipeInput(false);
    } else {
      setDisableEquipeInput(true);
      handleSetFilter(null, 'estabelecimento');
    }

    const hasSelectedNoBind = !!estabelecimentosSelected.find(
      (item) => item === 'null'
    );

    if (
      estabelecimentosSelected.length > 1 ||
      estabelecimentosSelected.length === 0
    ) {
      setDisableEquipeInput(true);
    } else {
      setDisableEquipeInput(false);
    }
    if (hasSelectedNoBind) {
      setDisableEquipeInput(true);
    }
  }, [estabelecimentosSelected]);

  useEffect(() => {
    if (equipesSelected.length > 0) {
      handleSetFiltersInMultiplesInputs({
        value: equipesSelected[0] ?? '',
        fieldName: 'equipe',
        arrayValues: equipesSelected,
      });
    } else {
      handleSetFilter(null, 'equipe');
    }
  }, [equipesSelected]);

  useEffect(() => {
    const getEquipes = async () => {
      const hasSelectedNoBind = !!estabelecimentosSelected.find(
        (item) => item === 'null'
      );
      if (estabelecimentosSelected.length === 1) {
        if (!hasSelectedNoBind) {
          const response = await api.get('/equipe/estabelecimento/get', {
            params: {
              estabelecimentoId: estabelecimentosSelected[0],
            },
          });
          setArrayEquipes([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
          setAllEquipesOptions([
            {
              id: 'null',
              municipio: '',
              nome: 'SEM VINCULAÇÃO',
              quantidade: '',
              label: '',
            },
            ...response.data,
          ]);
        }
      }

      if (estabelecimentosSelected.length === 0) {
        setArrayEquipes([]);
      }
    };

    getEquipes();
  }, [estabelecimentosSelected]);

  useEffect(() => {
    const loadEstados = async () => {
      // const response = await getEstados();
      setEstadosOptions([
        {
          id: 13,
          created_at: '2023-03-21T00:36:08.490Z',
          updated_at: '2023-03-21T00:36:08.490Z',
          nome: 'Pernambuco',
          sigla: 'PE',
          codigo: '26',
        },
      ]);

      setAllEstadosOptions([
        {
          id: 13,
          created_at: '2023-03-21T00:36:08.490Z',
          updated_at: '2023-03-21T00:36:08.490Z',
          nome: 'Pernambuco',
          sigla: 'PE',
          codigo: '26',
        },
      ]);
    };

    loadEstados();
  }, []);

  useEffect(() => {
    setPayload({
      params: {
        estados:
          isPadrao || isMunicipal || isEstadual
            ? 'PE'
            : estadosSelected.length > 0
            ? JSON.stringify(estadosSelected)
            : undefined,
        municipios:
          isPadrao || isMunicipal
            ? userToken.endereco.municipio_id
            : municipiosSelected.length > 0
            ? municipiosSelected.join(', ')
            : undefined,
        estabelecimentos: isPadrao
          ? userToken.estabelecimentoId
          : estabelecimentosSelected.length > 0
          ? estabelecimentosSelected.join(', ')
          : undefined,
        equipe:
          equipesSelected.length > 0 ? equipesSelected.join(', ') : undefined,
        periodo_inicio: period?.periodo_inicio
          ? period.periodo_inicio
          : undefined,
        periodo_fim: period?.periodo_fim ? period.periodo_fim : undefined,
      },
    });
  }, [
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    equipesSelected,
    period,
  ]);

  useEffect(() => {
    const getOptionsCidades = async () => {
      if (estadosSelected.length === 1) {
        const optionsCidades = await getCidades(estadosSelected[0]);
        setOptionsMunicipios(optionsCidades);
        setAllMunicipiosOptions(optionsCidades);
      }

      if (estadosSelected.length === 0) {
        // setOptionsMunicipios([]);
      }
    };

    getOptionsCidades();
  }, [estadosSelected]);

  useEffect(() => {
    const getOptionsEstabelecimentos = async () => {
      if (municipiosSelected.length === 1) {
        const optionsEstabelecimentos = await api.get(
          '/exames/colposcopia/panel/estabelecimentos',
          {
            params: {
              estados:
                estadosSelected.length > 0
                  ? JSON.stringify(estadosSelected)
                  : undefined,
              municipios:
                municipiosSelected.length > 0
                  ? JSON.stringify(municipiosSelected)
                  : undefined,
            },
          }
        );

        setOptionsEstabelecimentos([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        setAllEstabelecimentosOptions([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        if (isPadrao) {
          setEstabelecimentosSelected([userToken.estabelecimentoId]);
        }
      }

      if (municipiosSelected.length === 0) {
        if (!isPadrao) {
          setOptionsEstabelecimentos([]);
        }
      }
    };

    getOptionsEstabelecimentos();
  }, [municipiosSelected]);

  // useEffect(() => {
  //   if (isPadrao || isMunicipal || isEstadual) {
  //     setDisableInputEstado(true);
  //   }
  //   const today = moment().format('YYYY-MM-DD');
  //   const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');
  //   setTimeout(() => {
  //     setPeriod({
  //       periodo_fim: today,
  //       periodo_inicio: twelveMonths,
  //     });
  //     getAllCharts({
  //       cleared: false,
  //       payload: {
  //         params: {
  //           ...payload,
  //           periodo_fim: today,
  //           periodo_inicio: twelveMonths,
  //         },
  //       },
  //     });
  //   }, 110);
  // }, []);

  return (
    <>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue: any) => {
                  handleSelectPeriodModal(newValue);
                }}
                options={optionsPeriodo}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod === 'Últimos 12 meses' ||
              valueInputPeriod === 'Todos' ||
              !valueInputPeriod ? undefined : (
                <Autocomplete
                  options={['2024', '2023']}
                  onChange={(_, newValue) => {
                    handleChangeWhenYearSelected(newValue);
                  }}
                  value={year ? year : ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione o Ano" />
                  )}
                />
              )}
              {}

              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? optionsQuadrimestre
                      : optionsSemestre
                  }
                  value={
                    valueInputQuadrimestreSemestre
                      ? valueInputQuadrimestreSemestre
                      : ''
                  }
                  onChange={(_, newValue) => {
                    handleChangeSemestreOrQuadrimestre(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        valueInputPeriod === 'Quadrimestral'
                          ? 'Selecione o Quadrimestre'
                          : 'Selecione o Semestre'
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
            }}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalPeriodo(false)}
            endIcon={<CheckIcon />}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <MultipleFilters
          routeGetEstabelecimentos="/dashboard/rastreamento/hpv/estabelecimentos"
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          mdEstado={2.6}
          mdMunicipio={2.6}
          mdEstabelecimento={2.6}
          mdEquipe={2}
          // fieldEquipe
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />
        <Grid item xs={12} md={2}>
          <Controller
            name="periodo"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  data-testid="periodoInput"
                  fullWidth
                  size="small"
                  label="Período"
                  value={valueInputPeriod ? valueInputPeriod : ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => setOpenModalPeriodo(true)}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            variant="contained"
            data-testid="submitFilterPanelHpv"
            sx={{ width: '100%' }}
            onClick={() => {
              getAllCharts({ cleared: false, payload: payload });
              setShowListFilter(listFilter);
              setPeriodToDisplay(valueInputPeriod);
            }}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            data-testid="clearFilterPanelHpv"
            variant="outlined"
            color="error"
            sx={{ width: '100%' }}
            onClick={() => {
              handleClearFilter();
            }}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FiltersVisaoGeral;
