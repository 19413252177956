/* eslint-disable react-hooks/exhaustive-deps */

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import { getEstados } from '../../../../lib/getEstados';
import useHandleChangesFilters from './hooks/useHandleChangesFilters';
import { optionsResultadoHPV } from '../../../../utils/variables';

import useRequestFilter from './hooks/useRequestFilter';
import useVerifyAccessLevel from './hooks/useVerifyAccessLevel';
import useSetPayload from './hooks/useSetPayload';
import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import useClearValues from './hooks/useClearValues';
import api from '../../../../api';
import MultipleFilters from '../../../../components/MultipleFilters';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { SelectWithSearch } from '../../../../components/SelectWithSearch';
import { getUserToken } from '../../../../lib/auth';
import { getProfissionaisServicosSaude } from '../../../../lib/getProfissionaisServicosSaude';

const FiltersLesoesIntraepiteliais = ({
  isAdminHospital,
  isAdminLaboratorio,
  isOutrosHospital,
  setTableData,
  control,
  handleSubmit,
  setValue,
  watch,
  setError,
  clearErrors,
  errors,
  estadosSelected,
  setEstadosSelected,
  municipiosSelected,
  setMunicipiosSelected,
  estabelecimentosSelected,
  setEstabelecimentosSelected,
  setShowTableData,
  showTableData,
  setListFilter,
  listFilter,
  initialStateListFilter,
  listFilterShow,
  setListFilterShow,
  period,
  periodResultado,
  setPeriodResultado,
  setPeriod,
  disableInputEstado,
  setListFilterToShow,
  dtColetaIni,
  setDataColetaIni,
  dtColetaFinal,
  setDataColetaFinal,
  dtResultadoIni,
  setDataResultadoIni,
  dtResultadoFinal,
  setDataResultadoFinal,
  setSelectedServicos,
  selectedServicos,
  disabledServicos,
  setDisabledServicos,
  selectedTipoEzt,
  setSelectedTipoEzt,
  selectedAnestesia,
  setSelectedAnestesia,
  selectedLocal,
  setSelectedLocal,
  selectedEzt,
  setSelectedEzt,
  selectedTipoProcedimento,
  setSelectedTipoProcedimento,
  selectedHasHistoPos,
  setSelectedHasHistoPos,
  selectedHasHistoPre,
  setSelectedHasHistoPre,
}) => {
  const [disableOtherInputs, setDisableOtherInputs] = useState(true);

  const [estados, setEstados] = useState<any[]>([]);
  const [cidades, setCidades] = useState<any[]>([]);
  const [estabelecimentos, setEstabelecimentos] = useState<any[]>([]);
  const [professionalList, setProfessionalList] = useState<any[]>([]);
  const [valueDatePickerColetaIni, setValueDatePickerColetaIni] =
    useState<any>();

  // const [arrayEquipes, setArrayEquipes] = useState([]);
  // const [equipesSelected, setEquipesSelected] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [showMotivoNaoAtendimento, setShowMotivoNaoAtendimento] =
    useState(false);

  const [responsaveis, setResponsaveis] = useState([]);

  const [disableFilters, setDisableFilters] = useState({
    estado: false,
    municipio: false,
    estabelecimento: false,
    equipe: false,
  });

  const { loadReportsIntraepitelial } = useRequestFilter({ setTableData });

  const { currentEstablishment } = useContext(EstablishmentContext);

  const location = useLocation();

  const userToken = getUserToken();

  const {
    handleChangeUf,
    handleChangeCidade,
    handleChangeEstabelecimento,
    handleSetFilter,
    handleChangeDatePicker,
    handleChangeRealizouAtendimento,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  } = useHandleChangesFilters({
    setCidades,
    setEstabelecimentos,
    setValue,
    setListFilter,
    estados,
  });

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setSearchAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    errorsFields,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    setErrorsFields,
    setOptionsLaboratorios,
    setAllLaboratoriosOptions,

    setOptionsServicos,
    setAllServicosOptions,

    optionsServicos,
    allServicosOptions,
  } = useStatesFields();

  const { setPayload } = useSetPayload({
    watch,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    dtColetaFinal,
    dtColetaIni,
    dtResultadoIni,
    selectedServicos,
    dtResultadoFinal,
    selectedEzt,
    selectedLocal,
    selectedTipoEzt,
    selectedAnestesia,
    selectedTipoProcedimento,
    selectedHasHistoPos,
    selectedHasHistoPre,
  });

  const { setDefaultValues, permissao } = useVerifyAccessLevel({
    setValue,
    getCidadesEstabelecimentos,
    getEstabelecimento,
    currentEstablishment,
    setDisableFilters,
    handleSetFilter,
    setTableData,
    watch,
  });

  const { validationSubmit } = useActions();

  const handleSetSelectedOptions =
    ({ setSelected, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${fieldName}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${fieldName}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), 'resultado');
    };

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    loadReportsIntraepitelial,
    watch,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    showTableData,
    setTableData,
    setShowTableData,
    setErrorsFields,
    setListFilterToShow,
    setDataColetaIni,
    setDataColetaFinal,
    setDataResultadoFinal,
    setDataResultadoIni,
    isAdminHospital,
    isAdminLaboratorio,
    isOutrosHospital,
    setSelectedServicos,
    setSelectedTipoEzt,
    setSelectedAnestesia,
    setSelectedLocal,
    setSelectedEzt,
    setSelectedTipoProcedimento,
    setSelectedHasHistoPos,
    setSelectedHasHistoPre,
  });

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');

  const handleChangeEquipes = (equipe) => {
    const isSelected = equipesSelected?.find(
      (equipeId) => equipeId === equipe.id
    );

    if (isSelected) {
      setEquipesSelected((prev) => {
        return prev.filter((equipeId) => equipeId !== equipe.id);
      });
    } else {
      setEquipesSelected((prev) => {
        return [...prev, equipe.id];
      });
    }
  };

  const getEquipes = async (newValue) => {
    const response = await api.get('/equipe/estabelecimento/get', {
      params: {
        estabelecimentoId: newValue.id,
      },
    });
    setArrayEquipes(response.data);
  };

  const loadEstados = async () => {
    const response = await getEstados();
    setEstados(response);
  };

  const getProfissionaisList = async () => {
    if (selectedServicos.length === 1) {
      const response = await getProfissionaisServicosSaude({
        estadosSelected,
        municipiosSelected,
        estabelecimentosSelected,
        dtColetaIni,
        dtColetaFinal,
        servicoSaudeId: selectedServicos[0].value,
      });

      let arrayProfissionais: any = [];

      response.data.forEach((profissional) => {
        const objProfissionalFormated = {
          idprofissional: profissional.prof_id,
          nomeprofissional: profissional.pessoa.nome,
          label: profissional.pessoa.nome,
          // estabelecimentoprofissional:
          //   profissional.estabelecimentos[0].estabelecimento.id,
          // nomefantasiaestabelecimento:
          //   profissional.estabelecimentos[0].estabelecimento.nome_fantasia,
        };

        arrayProfissionais.push(objProfissionalFormated);
      });

      setProfessionalList(arrayProfissionais);
    } else {
      setProfessionalList([]);
    }
  };

  const fnWhenFormIsValid = async () => {
    setTableData([]);
    setListFilterShow(listFilter);

    setShowTableData(true);

    await setPayload().then((payload) => {
      try {
        loadReportsIntraepitelial(payload);
      } catch (error) {}
    });
  };

  const executeClearDisplay = () => {};

  const clearFilters = () => {
    setValue('profissionalId', null);
    setDataColetaIni(null);
    setDataColetaFinal(null);
    setValue('indicacao_ezt', '');
    setValue('tipo_ezt', '');
    setEstabelecimentosSelected([]);
    handleSetFilter('data_ini', '');
    handleSetFilter('data_fin', '');
    handleSetFilter('search', '');
    handleSetFilter('indicacao_ezt', '');
    handleSetFilter('tipo_ezt', '');
    handleSetFilter('responsavel_coleta', '');
    executeClearDisplay();

    if (isAdminHospital || isAdminLaboratorio || isOutrosHospital) {
      return;
    }
    handleSetFilter('estado', '');
    handleSetFilter('municipio', '');
    handleSetFilter('profissionalLaudo', '');
    handleSetFilter('servico_saude', '');

    setMunicipiosSelected([]);
    setEstadosSelected([]);
    setSelectedServicos([]);
  };

  const onSubmit = async () => {
    validationSubmit({
      fnWhenFormIsValid,
      estadosSelected,
      municipiosSelected,
      estabelecimentosSelected,
      setErrorsFields,
      setDisableOtherInputs,
      watch,
      setError,
      clearErrors,
    });
  };

  const handleSetPeriod = (e) => {
    setPeriod(e.target.value);
    setDataColetaFinal(null);
    setDataColetaIni(null);

    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_ini_resultado');
    });
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_fin_resultado');
    });

    handleSetFilter('', 'dt_ini_resultado');
    handleSetFilter('', 'dt_fin_resultado');
  };

  const handleSetPeriodResultado = (e) => {
    setPeriodResultado(e.target.value);
    setDataResultadoIni(null);
    setDataResultadoFinal(null);

    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_resultado_inicio');
    });
    setListFilterShow((prev) => {
      return prev.filter((field) => field.inputName !== 'dt_resultado_fim');
    });

    // handleSetFilter('', 'dt_resultado_fim');
  };

  useEffect(() => {
    setValue('estado', estadosSelected);
    setValue('municipio', municipiosSelected);
    setValue('estabelecimentoSaudeId', estabelecimentosSelected);
    setValue('estabelecimentoSaudeId', estabelecimentosSelected);
    setValue('equipes', equipesSelected);
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

  useEffect(() => {
    loadEstados();
  }, []);

  // useEffect(() => {
  //   getProfissionaisList();
  // }, [selectedServicos]);

  useEffect(() => {
    const state: any = location.state;
    async function loadResponsaveis() {
      const response = await api.get(
        '/dashboard/exames/intraepitelial-leag/panel/profissionais',
        {
          params: {
            estados:
              estadosSelected.length > 0
                ? estadosSelected.join(', ')
                : undefined,

            municipios:
              municipiosSelected.length > 0
                ? municipiosSelected.join(', ')
                : undefined,

            laboratorioId:
              selectedServicos.length > 0
                ? selectedServicos.map((servico) => servico.value).join(',')
                : undefined,
          },
        }
      );

      const profissionais = response.data.data.map((profissional) => {
        return {
          idprofissional: profissional.prof_id,
          nomeprofissional: `${profissional.pessoa.nome}`,
          label: `${profissional.pessoa.nome}`,
        };
      });

      setResponsaveis(profissionais);
    }
    if (selectedServicos?.length === 1) {
      loadResponsaveis();
    }

    if (selectedServicos?.length > 1 || selectedServicos?.length === 0) {
      setResponsaveis([]);
    }
  }, [selectedServicos]);

  const selectedChartIsResult = !!localStorage.getItem('result');
  const selectedChartIsTypeVirus = !!localStorage.getItem('typeVirus');
  const selectedChartIsReason = !!localStorage.getItem('reasonNotProcedure');
  const selectedChartOriginCollection =
    localStorage.getItem('collectionOrigin');

  const setListFilterDisplayByCharts = () => {
    if (selectedChartIsResult) {
      const value = localStorage.getItem('result');
      handleSetFilter(
        optionsResultadoHPV.find((item) => item.value === value)?.label,
        'resultado'
      );
    }
  };

  useEffect(() => {
    if (
      !!location.state ||
      selectedChartIsResult ||
      selectedChartIsTypeVirus ||
      selectedChartIsReason ||
      selectedChartOriginCollection
    ) {
      setListFilterDisplayByCharts();
    }
  }, []);

  // useEffect(() => {
  //   if (showTableData) {
  //     setDefaultValues().then((payload) => {
  //       if (payload === 'waiting') {
  //         return;
  //       } else {
  //         loadReportsHpv(payload);
  //       }
  //     });
  //   }
  // }, [estados, currentEstablishment, showTableData]);

  const estabelecimentoInputValue = watch('estabelecimento_saude');

  useEffect(() => {
    if (!estabelecimentoInputValue) {
      setValue('motivo', null);
      setValue('idade_ini', null);
      setValue('idade_fin', null);
      setValue('realizou_atendimento', null);
      setValue('atendimento_status', null);
      setValue('inspecao_colo', null);
      setValue('responsavel_coleta', null);
      setValue('dt_coleta', null);
      setValue('resultado', null);
      setValue('estabelecimentoLaudoId', null);
      setValue('dt_resultado', null);
      setValue('search', null);

      initialStateListFilter.forEach((field) => {
        const isMunicipio = field.inputName === 'municipio';
        const isEstado = field.inputName === 'estado';
        if (isEstado || isMunicipio) {
          return;
        } else {
          handleSetFilter('', field.inputName);
        }
      });
    }
  }, [estabelecimentoInputValue]);

  useEffect(() => {
    const isAllEmpty = !!listFilter.find((item) => item.value !== '') === false;

    if (isAllEmpty) {
      setListFilterShow([]);
    }
  }, [listFilter]);

  const optionsTipoEzt = [
    {
      label: 'Tipo 1',
      value: '1',
    },
    {
      label: 'Tipo 2',
      value: '2',
    },
    {
      label: 'Tipo 3',
      value: '3',
    },
  ];

  const optionsIndicacaoEzt = [
    {
      label: 'Terapêutica: Biópsia prévia com lesão de alto grau',
      value: '1',
    },
    {
      label: 'Terapêutica: Critérios ver e tratar',
      value: '2',
    },
    {
      label: 'Diagnóstica: Investigação do canal',
      value: '3',
    },
  ];

  // useEffect(() => {
  //   const hasEstadosSelected = estadosSelected.length > 0;
  //   const hasMunicipioSelected = municipiosSelected.length > 0;
  //   const hasEstabelecimentoSelected = estabelecimentosSelected.length > 0;

  //   if (
  //     hasEstadosSelected &&
  //     hasMunicipioSelected &&
  //     hasEstabelecimentoSelected
  //   ) {
  //     setDisableOtherInputs(false);
  //   } else {
  //     setDisableOtherInputs(true);
  //   }
  // }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

  useEffect(() => {
    if (
      (optionsMunicipios?.length > 0 &&
        municipiosSelected?.length === optionsMunicipios?.length) ||
      municipiosSelected.length > 0
    ) {
      setDisableOtherInputs(false);
      setDisabledServicos(false);
    }
  }, [optionsMunicipios, municipiosSelected]);

  useEffect(() => {
    if (municipiosSelected.length === 0 || !municipiosSelected) {
      setDisableOtherInputs(true);
    }
  }, [municipiosSelected]);

  const searchInput = watch('search');

  useEffect(() => {
    if (!searchInput) {
      clearErrors('search');
    }
  }, [searchInput]);

  const [errorInputDateResultado, setErrorInputDateResultado] = useState<any>(
    {}
  );

  useEffect(() => {
    const dtIni = moment(dtColetaIni, 'DD-MM-YYYY');
    const dtFin = moment(dtColetaFinal, 'DD-MM-YYYY');

    if (dtIni.isAfter(dtFin)) {
      setErrorInputDateResultado({
        fin: 'Data final deve ser maior que a data inicial',
        ini: '',
      });
    } else {
      setErrorInputDateResultado('');
    }
  }, [dtColetaIni, dtColetaFinal]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DisplayFilter style={{ marginTop: '24px' }}>
            {!listFilterShow?.find((item) => item.value !== '') && (
              <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                Os filtros selecionados aparecerão aqui.
              </p>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              {listFilterShow.map((item) => (
                <>
                  {item.value ? (
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  ) : undefined}
                </>
              ))}
            </div>
          </DisplayFilter>
        </Grid>
        <Grid sx={{ paddingLeft: '20px' }} container spacing={3} mt={3}>
          <MultipleFilters
            setOptionsServicos={setOptionsServicos}
            setAllServicosOptions={setAllServicosOptions}
            setOptionsLaboratorios={setOptionsLaboratorios}
            setAllLaboratoriosOptions={setAllLaboratoriosOptions}
            routeGetEstabelecimentos="/dashboard/exames/citologia/estabelecimentos"
            setDisableOtherInputs={setDisableOtherInputs}
            disableInputEstado={disableInputEstado}
            handleSetFilter={handleSetFilter}
            // laboratorioField
            mdEstado={4}
            mdMunicipio={4}
            mdEstabelecimento={4}
            mdEquipe={3}
            acsSelected={acsSelected}
            arrayAcs={arrayAcs}
            searchAcs={searchAcs}
            allAcsOptions={allAcsOptions}
            setAllAcsOptions={setAllAcsOptions}
            anchorElAcs={anchorElAcs}
            disableAcsInput={disableAcsInput}
            setAnchorElAcs={setAnchorElAcs}
            setArrayAcs={setArrayAcs}
            setSearchAcs={setArrayAcs}
            setAcsSelected={setAcsSelected}
            anchorElEstados={anchorElEstados}
            searchEstado={searchEstado}
            estadosOptions={estadosOptions}
            allEstadosOptions={allEstadosOptions}
            estadosSelected={estadosSelected}
            setAnchorElEstados={setAnchorElEstados}
            setSearchEstado={setSearchEstado}
            setEstadosOptions={setEstadosOptions}
            setAllEstadosOptions={setAllEstadosOptions}
            setEstadosSelected={setEstadosSelected}
            allMunicipiosOptions={allMunicipiosOptions}
            searchMunicipio={searchMunicipio}
            anchorElMunicipios={anchorElMunicipios}
            municipiosSelected={municipiosSelected}
            optionsMunicipios={optionsMunicipios}
            disableMunicipioInput={disableMunicipioInput}
            setAllMunicipiosOptions={setAllMunicipiosOptions}
            setSearchMunicipio={setSearchMunicipio}
            setAnchorElMunicipios={setAnchorElMunicipios}
            setMunicipiosSelected={setMunicipiosSelected}
            setOptionsMunicipios={setOptionsMunicipios}
            optionsEstabelecimentos={optionsEstabelecimentos}
            allEstabelecimentosOptions={allEstabelecimentosOptions}
            searchEstabelecimentos={searchEstabelecimentos}
            anchorElEstabelecimentos={anchorElEstabelecimentos}
            estabelecimentosSelected={estabelecimentosSelected}
            disableEstabelecimentoInput={disableEstabelecimentoInput}
            setOptionsEstabelecimentos={setOptionsEstabelecimentos}
            setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
            setSearchEstabelecimentos={setSearchEstabelecimentos}
            setAnchorEstabelecimentos={setAnchorEstabelecimentos}
            setEstabelecimentosSelected={setEstabelecimentosSelected}
            allEquipesOptions={allEquipesOptions}
            searchEquipes={searchEquipes}
            anchorElEquipe={anchorElEquipe}
            arrayEquipes={arrayEquipes}
            equipesSelected={equipesSelected}
            disableEquipeInput={disableEquipeInput}
            setAllEquipesOptions={setAllEquipesOptions}
            setSearchEquipes={setSearchEquipes}
            setAnchorElEquipe={setAnchorElEquipe}
            setArrayEquipes={setArrayEquipes}
            setEquipesSelected={setEquipesSelected}
            setDisabledMunicipioInput={setDisabledMunicipioInput}
            setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
            setDisableEquipeInput={setDisableEquipeInput}
            setDisableAcsInput={setDisableAcsInput}
            errorsFields={errorsFields}
            setErrorsFields={setErrorsFields}
            // clearValuesInputs={clearValuesInputs}
          />

          <Grid item xs={12} md={4}>
            <SelectWithSearch
              options={[
                {
                  label: 'Sim',
                  value: 'true',
                },
                { label: 'Não', value: 'false' },
              ]}
              label="Realizou Histopatologia Pós-Tratamento?"
              disabled={disableOtherInputs}
              selectedOptions={selectedHasHistoPos}
              setSelectedOptions={setSelectedHasHistoPos}
              setSelected={handleSetSelectedOptions({
                setSelected: setSelectedHasHistoPos,
                fieldName: 'Realizou Histopatologia Pós-TratamentoT',
              })}
              dataTestIdModal="modalResultInput"
              dataTestIdInputSearch="inputSearchResultado"
              dataTestSelectAll="selectAllResultados"
              dataTestClearAll="clearResultados"
            />
            {/* <Controller
              control={control}
              name="has_histo_pre"
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    disabled={disableOtherInputs}
                    value={value ? value : ''}
                    onChange={onChange}
                    label="Realizou Histopatologia Pré-Tratamento?"
                  >
                    <MenuItem value={'true'}>Sim</MenuItem>
                    <MenuItem value={'false'}>Não</MenuItem>
                  </TextField>
                );
              }}
            /> */}
          </Grid>
          <Grid item xs={12} md={4}>
            {/* <Controller
              control={control}
              name="has_histo_pos"
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    disabled={disableOtherInputs}
                    value={value ? value : ''}
                    onChange={onChange}
                    label="Realizou Histopatologia Pós-Tratamento?"
                  >
                    <MenuItem value={'true'}>Sim</MenuItem>
                    <MenuItem value={'false'}>Não</MenuItem>
                  </TextField>
                );
              }}
            /> */}

            <SelectWithSearch
              options={[
                {
                  label: 'Sim',
                  value: 'true',
                },
                { label: 'Não', value: 'false' },
              ]}
              label="Realizou Histopatologia Pré-Tratamento?"
              disabled={disableOtherInputs}
              selectedOptions={selectedHasHistoPre}
              setSelectedOptions={setSelectedHasHistoPre}
              setSelected={handleSetSelectedOptions({
                setSelected: setSelectedHasHistoPre,
                fieldName: 'Realizou Histopatologia Pré-TratamentoT',
              })}
              dataTestIdModal="modalResultInput"
              dataTestIdInputSearch="inputSearchResultado"
              dataTestSelectAll="selectAllResultados"
              dataTestClearAll="clearResultados"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithSearch
              options={[
                {
                  label: 'Terapêutica: Biópsia prévia com lesão de alto grau',
                  value: 1,
                },
                { label: 'Terapêutica: Critérios ver e tratar', value: 2 },
                { label: 'Diagnóstica: Investigação do canal', value: 3 },
              ]}
              label="Indicação da EZT"
              disabled={disableOtherInputs}
              selectedOptions={selectedEzt}
              setSelectedOptions={setSelectedEzt}
              setSelected={handleSetSelectedOptions({
                setSelected: setSelectedEzt,
                fieldName: 'Indicação da EZT',
              })}
              dataTestIdModal="modalResultInput"
              dataTestIdInputSearch="inputSearchResultado"
              dataTestSelectAll="selectAllResultados"
              dataTestClearAll="clearResultados"
            />
            {/* <Controller
              control={control}
              name="indicacao_ezt"
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    label="Indicação da EZT"
                    fullWidth
                    size="small"
                    select
                    InputLabelProps={{ shrink: !!value }}
                    disabled={disableOtherInputs}
                    value={value ? value : null}
                    onChange={(e) => {
                      onChange(e.target.value);
                      if (e.target.value) {
                        handleSetFilter(
                          `Indicação da EZT: ${
                            optionsIndicacaoEzt?.find(
                              (opt) => opt.value === e.target.value
                            )?.label
                          }`,
                          'indicacao_ezt'
                        );
                      } else {
                        handleSetFilter('', 'indicacao_ezt');
                      }
                    }}
                  >
                    {optionsIndicacaoEzt.map((opt) => {
                      return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                    })}
                  </TextField>
                );
              }}
            /> */}
          </Grid>

          <Grid item xs={12} md={4}>
            {isAdminHospital || isOutrosHospital || isAdminLaboratorio ? (
              <TextField
                disabled
                fullWidth
                size="small"
                value={userToken.estabelecimento_saude.nome_fantasia}
                label="Serviço de Saúde da Realização"
              />
            ) : (
              <SelectWithSearch
                options={optionsServicos}
                label="Serviço de Saúde da Realização"
                disabled={disableOtherInputs || disabledServicos}
                selectedOptions={selectedServicos}
                setSelectedOptions={setSelectedServicos}
                setSelected={handleSetSelectedOptions({
                  setSelected: setSelectedServicos,
                  fieldName: 'Serviço de Saúde da Realização',
                })}
                dataTestIdModal="modalResultInput"
                dataTestIdInputSearch="inputSearchResultado"
                dataTestSelectAll="selectAllResultados"
                dataTestClearAll="clearResultados"
              />
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              name="profissionalId"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    data-testid="inputProfissionalResultadoId"
                    disabled={disableOtherInputs}
                    options={responsaveis}
                    onChange={(_, newValue) => {
                      onChange(newValue);
                      handleSetFilter(
                        `Profissional Responsável: ${newValue?.nomeprofissional}`,
                        'profissional_responsavel'
                      );
                    }}
                    value={value ? value : ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        data-testid="profissionalLaudo"
                        label="Profissional Responsável"
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithSearch
              options={[
                { label: 'Ambulatorial', value: 1 },
                { label: 'Sala de Procedimentos/Pequenas cirurgias', value: 2 },
                { label: 'Bloco Cirúrgico', value: 3 },
              ]}
              label="Local de Realização"
              disabled={disableOtherInputs || disabledServicos}
              selectedOptions={selectedLocal}
              setSelectedOptions={setSelectedLocal}
              setSelected={handleSetSelectedOptions({
                setSelected: setSelectedLocal,
                fieldName: 'Local de Realização',
              })}
              dataTestIdModal="modalResultInput"
              dataTestIdInputSearch="inputSearchResultado"
              dataTestSelectAll="selectAllResultados"
              dataTestClearAll="clearResultados"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithSearch
              options={[
                { label: 'Tipo 1', value: 1 },
                { label: 'Tipo 2', value: 2 },
                { label: 'Tipo 3', value: 3 },
              ]}
              label="Tipo de Exérese da EZT"
              disabled={disableOtherInputs}
              selectedOptions={selectedTipoEzt}
              setSelectedOptions={setSelectedTipoEzt}
              setSelected={handleSetSelectedOptions({
                setSelected: setSelectedTipoEzt,
                fieldName: 'Tipo de Exérese da EZT',
              })}
              dataTestIdModal="modalResultInput"
              dataTestIdInputSearch="inputSearchResultado"
              dataTestSelectAll="selectAllResultados"
              dataTestClearAll="clearResultados"
            />
            {/* <Controller
              control={control}
              name="tipo_ezt"
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    label="Tipo de Exérese da EZT"
                    fullWidth
                    InputLabelProps={{ shrink: !!value }}
                    size="small"
                    select
                    disabled={disableOtherInputs}
                    value={value ? value : null}
                    onChange={(e) => {
                      onChange(e.target.value);
                      if (e.target.value) {
                        handleSetFilter(
                          `Tipo de Exérese da EZT: ${
                            optionsTipoEzt?.find(
                              (opt) => opt.value === e.target.value
                            )?.label
                          }`,
                          'tipo_ezt'
                        );
                      } else {
                        handleSetFilter('', 'tipo_ezt');
                      }
                    }}
                  >
                    {optionsTipoEzt.map((opt) => {
                      return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                    })}
                  </TextField>
                );
              }}
            /> */}
            {/* <FormControl fullWidth size="small">
              <InputLabel>Tipo de Exérese da EZT </InputLabel>
              <Select
                // value={servicoSaude}
                label="Age"
                // onChange={handleChangeServicoSaude}
              >
                <MenuItem value={'Tipo 1'}>
                  Terapêutica: Biópsia prévia com lesão de alto grau
                </MenuItem>
                <MenuItem value={'Tipo 2'}>
                  Terapêutica: Critérios ver e tratar
                </MenuItem>
                <MenuItem value={'Tipo 3'}>
                  Diagnóstica: Investigação do canal
                </MenuItem>
              </Select>
            </FormControl> */}
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithSearch
              options={[
                { label: 'Local', value: 1 },
                { label: 'Sedação', value: 2 },
                { label: 'Raqui', value: 3 },
                { label: 'Outras', value: 4 },
              ]}
              label="Anestesia Utilizada"
              disabled={disableOtherInputs}
              selectedOptions={selectedAnestesia}
              setSelectedOptions={setSelectedAnestesia}
              setSelected={handleSetSelectedOptions({
                setSelected: setSelectedAnestesia,
                fieldName: 'Anestesia Utilizada',
              })}
              dataTestIdModal="modalResultInput"
              dataTestIdInputSearch="inputSearchResultado"
              dataTestSelectAll="selectAllResultados"
              dataTestClearAll="clearResultados"
            />{' '}
          </Grid>

          <Grid item xs={12} md={4}>
            <SelectWithSearch
              options={[
                {
                  label:
                    'Por alça diatérmica: Excisão com uma passagem da alça diatérmica',
                  value: 1,
                },
                {
                  label:
                    'Por alça diatérmica: Excisão com múltiplas passagens da alça diatérmica com ampliação de margens laterais',
                  value: 2,
                },
                {
                  label:
                    'Por alça diatérmica: Excisão com múltiplas passagens da alça diatérmica com ampliação de canal endocervical (margem endocervical)',
                  value: 3,
                },
                {
                  label: 'Por bisturi a fio: Cone clássico',
                  value: 4,
                },
              ]}
              label="Tipo de Procedimento"
              disabled={disableOtherInputs}
              selectedOptions={selectedTipoProcedimento}
              setSelectedOptions={setSelectedTipoProcedimento}
              setSelected={handleSetSelectedOptions({
                setSelected: setSelectedTipoProcedimento,
                fieldName: 'Tipo de Procedimento',
              })}
              dataTestIdModal="modalResultInput"
              dataTestIdInputSearch="inputSearchResultado"
              dataTestSelectAll="selectAllResultados"
              dataTestClearAll="clearResultados"
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              gap={3}
            >
              <Typography fontWeight="bold">Data do Resultado:</Typography>

              <FormControl>
                <RadioGroup
                  aria-labelledby="handleSetPeriod"
                  name="handleSetPeriod"
                  value={period}
                  onChange={handleSetPeriod}
                  row
                >
                  <FormControlLabel
                    value="date"
                    control={<Radio />}
                    data-testid="filterPerDate"
                    label="Filtrar por Data"
                  />
                  <FormControlLabel
                    value="period"
                    data-testid="filterPerPeriod"
                    control={<Radio />}
                    label="Filtrar por período"
                  />
                </RadioGroup>
              </FormControl>

              {period === 'period' && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  flex="1"
                  rowGap={1}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <DatePicker
                      data-testid="periodDatepickerInitialData"
                      onChange={(date) => {
                        handleSetFilter(
                          date ? moment(date).format('DD-MM-YYYY') : '',
                          'data_ini_resultado'
                        );

                        setDataColetaIni(
                          date ? moment(date).format('DD-MM-YYYY') : null
                        );
                      }}
                      maxDate={new Date()}
                      disabled={disableOtherInputs}
                      value={
                        dtColetaIni &&
                        moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                          ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                          : null
                      }
                      renderInput={(params) => (
                        <StyledAsteriskTextField
                          {...params}
                          label="Início da Data da realização"
                          size="small"
                          variant="outlined"
                          sx={{ minWidth: '200px' }}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <DatePicker
                      data-testid="periodDatepickerEndData"
                      onChange={(date) => {
                        handleSetFilter(
                          date ? moment(date).format('DD-MM-YYYY') : '',
                          'data_fin_resultado'
                        );
                        setDataColetaFinal(
                          date ? moment(date).format('DD-MM-YYYY') : null
                        );
                      }}
                      disabled={disableOtherInputs}
                      maxDate={new Date()}
                      value={
                        dtColetaFinal &&
                        moment(dtColetaFinal, 'DD-MM-YYYY').isValid()
                          ? moment(dtColetaFinal, 'DD-MM-YYYY').toDate()
                          : null
                      }
                      renderInput={(params) => (
                        <StyledAsteriskTextField
                          {...params}
                          error={!!errorInputDateResultado?.fin}
                          helperText={errorInputDateResultado?.fin}
                          label="Fim da Data da realização"
                          size="small"
                          variant="outlined"
                          sx={{
                            minWidth: '200px',
                            marginBottom: !!errorInputDateResultado?.fin
                              ? '-20px'
                              : '',
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}

              {period === 'date' && (
                <>
                  <>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ptBR}
                    >
                      <DatePicker
                        disabled={disableOtherInputs}
                        data-testid="datePicker"
                        onChange={(date) => {
                          setDataColetaIni(
                            date ? moment(date).format('DD-MM-YYYY') : null
                          );
                          setDataColetaFinal(
                            date ? moment(date).format('DD-MM-YYYY') : null
                          );
                        }}
                        maxDate={new Date()}
                        value={
                          dtColetaIni &&
                          moment(dtColetaIni, 'DD-MM-YYYY').isValid()
                            ? moment(dtColetaIni, 'DD-MM-YYYY').toDate()
                            : null
                        }
                        renderInput={(params) => (
                          <StyledAsteriskTextField
                            {...params}
                            label="Data da realização"
                            size="small"
                            variant="outlined"
                            sx={{ flex: 1 }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </>
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" gap={2}>
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      data-testid="inputSearchByNameCpf"
                      label="Pesquisar"
                      size="small"
                      placeholder="Pesquise por Nome, Nº do Cartão SUS ou CPF da paciente"
                      sx={{
                        'input::placeholder': {
                          color: 'black', // Substitua 'gray' pela cor desejada
                        },
                        '.MuiOutlinedInput-root': { paddingRight: '9px' },
                      }}
                      value={value ? value : ''}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      error={!!errors?.search}
                      helperText={errors?.search?.message}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <Button sx={{ padding: 0, minWidth: '24px' }}>
                            <SearchIcon
                              sx={{
                                cursor: 'pointer',
                                color: 'gray',
                              }}
                            />
                          </Button>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }}
              />

              <Button
                sx={{ padding: '6px 22px' }}
                variant="contained"
                type="submit"
                data-testid="submitFilterReportsHpv"
              >
                Filtrar
              </Button>

              <Button
                sx={{ padding: '6px 22px' }}
                variant="outlined"
                color="error"
                onClick={() => clearValues()}
                data-testid="clearFilterReportsHpv"
              >
                Limpar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersLesoesIntraepiteliais;
