import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { PieChart } from '../pieChart';
import { useNavigate } from 'react-router-dom';
import EmptyData from '../../../../../components/Charts/emptyData';

interface ExamResultsChartProps {
  data: number[];
  categories?: String[];
  height?: number;
  payload: any;
}

export function ExamResultsChart({ data, payload }: ExamResultsChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();
  const navigate = useNavigate();
  const emptyData = !data.find((item) => item > 1);

  const handleBarClick = (event, chartContext, config) => {};
  useEffect(() => {
    setChartData({
      series: data,
      // noData: {
      //   text: 'Não há dados disponíveis',
      //   align: 'left',
      //   verticalAlign: 'middle',
      //   offsetX: 100,
      //   offsetY: -20,
      //   style: {
      //     color: undefined,
      //     fontSize: '14px',
      //   },
      // },
      chart: {
        width: 284,
        type: 'pie',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (config.dataPointIndex > -1) {
              const values = {
                0: 'NEGATIVO_INDETECTADO',
                1: 'POSITIVO',
                2: 'INVALIDO',
              };

              localStorage.removeItem('reasonNotProcedure');
              localStorage.removeItem('typeVirus');
              localStorage.removeItem('reasonNotProcedure');
              localStorage.removeItem('collectionOrigin');

              localStorage.setItem('result', values[config.dataPointIndex]);
              localStorage.setItem('requestFromTable', 'true');
              localStorage.setItem('payload', JSON.stringify(payload));

              window.location.href = '/relatorios/hpv';
            }
          },
        },
      },
      labels: [
        `Não detectável (${data[0] ?? '0'})`,
        `Detectável (${data[1] ?? '0'})`,
        `Inconclusivo (${data[2] ?? '0'})`,
      ],
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              offsetY: 0,
              horizontalAlign: 'left',
              width: 250,
            },
          },
        },
      ],
      colors: ['#4CAF50', '#F44336', '#ACB7B8'],
      yaxis: {
        min: 0,
      },
      xaxis: {
        min: 0,
      },
      legend: {
        position: 'right',
        offsetY: 80,
      },
      plotOptions: {
        pie: {
          startAngle: -135,
          endAngle: 225,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
    });
  }, [data]);

  return (
    <>
      {chartData ? (
        <>
          <div style={{ display: 'none' }}>
            <ul>
              {data.map((value, index) => {
                const total = data[0] + data[1] + data[2];
                const percentNotDetectable = `${Number(
                  (data[0] / total) * 100
                ).toFixed(1)}%`;
                const percentDetectabel = `${Math.round(
                  (data[1] / total) * 100
                )}%`;
                const percentNotConcluded = `${Math.round(
                  (data[2] / total) * 100
                )}%`;
                return (
                  <>
                    {index === 0 && (
                      <li>
                        <p data-testid="labelAndValueNotDetectabel">
                          Não detectável ({value})
                        </p>
                        <p data-testid="percentNotDetectable">
                          percentNotDetectable: {percentNotDetectable}
                        </p>
                      </li>
                    )}
                    {index === 1 && (
                      <li>
                        <p data-testid="labelAndValueDetectable">
                          Detectável ({value})
                        </p>
                        <p data-testid="percentDetectabel">
                          percentDetectabel: {percentDetectabel}
                        </p>
                      </li>
                    )}
                    {index === 2 && (
                      <li>
                        <p data-testid="labelAndValueNotConcluded">
                          Inconclusivo ({value})
                        </p>
                        {/* <p data-testid="percentProfissional">
                          percentNotConcluded: {percentNotConcluded}
                        </p> */}
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
          <div style={{ position: 'relative' }}>
            {emptyData && (
              <div
                style={{
                  marginTop: '-49px',
                  marginBottom: '179px',
                  position: 'absolute',
                  left: '20%',
                  top: '20%',
                }}
              >
                <EmptyData />
              </div>
            )}
            <PieChart chartData={chartData} height={284} />
          </div>
        </>
      ) : undefined}
    </>
  );
}
