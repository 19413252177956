import { Box, Typography } from '@mui/material';
import styles from './styles.module.css';
import { ReactNode } from 'react';

interface TableCardProps {
  title: string;
  subtitle: string;
  children?: ReactNode;
  fullWidth?: boolean;
  valueInputPeriod?: string;
}

export function TableCard({
  title,
  subtitle,
  children,
  fullWidth,
  valueInputPeriod,
}: TableCardProps) {
  return (
    <div
      className={styles.container}
      style={{
        maxWidth: `${fullWidth ? '100%' : '566px'}`,
      }}
    >
      <Box px={2}>
        <Typography variant="h5" fontWeight="bold" className={styles.cardTitle}>
          {title} <br />
          {subtitle}
        </Typography>
        <p style={{ margin: 0, fontSize: '12px', color: '#9E9E9E' }}>
          {valueInputPeriod === 'Todos' || !valueInputPeriod
            ? 'Todos os períodos'
            : valueInputPeriod}
        </p>
      </Box>
      <Box mt={{ xs: 2, md: 7 }} mb={{ xs: 2, md: 4 }} pr={{ xs: 0, md: 4 }}>
        {children}
      </Box>
    </div>
  );
}