import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import moment from 'moment';
import api from '../../../../../api';

import { getUserToken } from '../../../../../lib/auth';
import { getCidades } from '../../../../../lib/getCidadesEstabelecimentos';

import { StyledAsteriskTextField } from '../../../../../components/StyledAsteriskTextField/styles';
import MultipleFilters from '../../../../../components/MultipleFilters';
import useStatesFields from '../../../../../components/MultipleFilters/hooks/useStatesFields';

interface Period {
  periodo_inicio?: string;
  periodo_fim?: string;
}

const PERIOD_OPTIONS = [
  { label: 'Todos', value: 'all' },
  { label: 'Últimos 12 meses', value: 'twelve_months' },
  { label: 'Quadrimestral', value: 'four_months' },
  { label: 'Semestral', value: 'six_months' },
  { label: 'Anual', value: 'yearly' },
];

const QUADRIMESTER_OPTIONS = [
  '1º Quadrimestre',
  '2º Quadrimestre',
  '3º Quadrimestre',
];
const SEMESTER_OPTIONS = ['1º Semestre', '2º Semestre'];
const YEAR_OPTIONS = ['2024', '2023'];

const FiltersVisaoGeral = ({
  control,
  setPayload,
  payload,
  getAllCharts,
  setListFilter,
  initialStateListFilter,
  setShowListFilter,
  listFilter,
  estabelecimentosSelected,
  estadosSelected,
  municipiosSelected,
  setEstabelecimentosSelected,
  setEstadosSelected,
  setMunicipiosSelected,
  valueInputPeriod,
  setValueInputPeriod,
  setPeriodToDisplay,
}) => {
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [period, setPeriod] = useState<Period>({});
  const [year, setYear] = useState('');
  // const [valuePeriodoModal, setValuePeriodoModal] = useState();
  const [valuePeriodoModal, setValuePeriodoModal] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState('');

  const userToken = getUserToken();
  const isPadrao = userToken.permissao_atual.id === 1;
  const isMunicipal = userToken.permissao_atual.id === 3;
  const isEstadual = userToken.permissao_atual.id === 4;

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,
    setAnchorElAcs,
    setArrayAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,
    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,
    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,
    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,
    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,
    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,
    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,
    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    // setDisableInputEstado,
    disableInputEstado,
    errorsFields,
    setErrorsFields,
  } = useStatesFields();

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      resetPeriodValues();
      return;
    }

    switch (newValue.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();
        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      case 'all':
        setValuesAllPeriodSelected();
        break;
      default:
        break;
    }
  };

  const resetPeriodValues = () => {
    setPeriod({});
    setYear('');
    setValueInputPeriod(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre('');
  };

  const setValuesInLastTwelveMonths = () => {
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    setPeriod({ periodo_fim: today, periodo_inicio: twelveMonths });
  };

  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setValueInputQuadrimestreSemestre('1º Quadrimestre');
    const currentYear = new Date().getFullYear();
    setYear(currentYear.toString());
    setPeriod({
      periodo_inicio: `${currentYear}-01-01`,
      periodo_fim: `${currentYear}-04-30`,
    });
  };

  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    setValueInputQuadrimestreSemestre('1º Semestre');
    const currentYear = new Date().getFullYear();
    setYear(currentYear.toString());
    setPeriod({
      periodo_inicio: `${currentYear}-01-01`,
      periodo_fim: `${currentYear}-06-30`,
    });
  };

  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    const currentYear = new Date().getFullYear();
    setYear(currentYear.toString());
    setPeriod({
      periodo_inicio: `${currentYear}-01-01`,
      periodo_fim: `${currentYear}-12-31`,
    });
  };

  const setValuesAllPeriodSelected = () => {
    setValueInputPeriod('Todos');
    setPeriod({ periodo_inicio: '2022-01-01' });
  };

  const handleClearFilter = () => {
    resetPeriodValues();
    setPeriodToDisplay('Todos os períodos');
    resetSelections();
    resetSearchFields();
    resetListFilter();

    const initialPayload = {
      params: {
        page: 1,
        size: 20,
        tipo: 'Reflexo',
      },
    };

    getAllCharts({ cleared: true, payload: initialPayload });
  };

  const resetSelections = () => {
    if (!isPadrao) setEstabelecimentosSelected([]);
    if (!isMunicipal && !isPadrao) setMunicipiosSelected([]);
    if (!isMunicipal && !isPadrao && !isEstadual) setEstadosSelected([]);
  };

  const resetSearchFields = () => {
    setSearchEquipes('');
    setSearchEstabelecimentos('');
    setSearchEstado('');
    setSearchMunicipio('');
  };

  const resetListFilter = () => {
    initialStateListFilter.forEach((input) => {
      handleSetFilter(null, input.inputName);
    });
    setShowListFilter([]);
  };

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (valueInputPeriod === 'Quadrimestral') {
      handleChangeSemestreOrQuadrimestre('1º Quadrimestre');
    } else if (valueInputPeriod === 'Semestral') {
      handleChangeSemestreOrQuadrimestre('1º Semestre');
    } else if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_inicio: `${newValue}-01-01`,
        periodo_fim: `${newValue}-12-31`,
      });
    }
  };
  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    if (!year) {
      console.error('Ano não selecionado');
      return;
    }

    const periods = {
      '1º Semestre': { inicio: '01-01', fim: '06-30' },
      '2º Semestre': { inicio: '07-01', fim: '12-31' },
      '1º Quadrimestre': { inicio: '01-01', fim: '04-30' },
      '2º Quadrimestre': { inicio: '05-01', fim: '08-31' },
      '3º Quadrimestre': { inicio: '09-01', fim: '12-31' },
      '1º Bimestre': { inicio: '01-01', fim: '02-28' },
      '2º Bimestre': { inicio: '03-01', fim: '04-30' },
      '3º Bimestre': { inicio: '05-01', fim: '06-30' },
      '4º Bimestre': { inicio: '07-01', fim: '08-31' },
      '5º Bimestre': { inicio: '09-01', fim: '10-31' },
      '6º Bimestre': { inicio: '11-01', fim: '12-31' },
    };

    const selectedPeriod = periods[newValue];
    if (selectedPeriod) {
      setPeriod({
        periodo_inicio: `${year}-${selectedPeriod.inicio}`,
        periodo_fim: `${year}-${selectedPeriod.fim}`,
      });
    } else {
      console.error('Período inválido selecionado');
    }
  };

  const handleCloseModal = () => {
    if (valueInputPeriod && year) {
      if (
        valueInputPeriod === 'Quadrimestral' ||
        valueInputPeriod === 'Semestral'
      ) {
        handleChangeSemestreOrQuadrimestre(valueInputQuadrimestreSemestre);
      } else if (valueInputPeriod === 'Anual') {
        setPeriod({
          periodo_inicio: `${year}-01-01`,
          periodo_fim: `${year}-12-31`,
        });
      }
    } else if (valueInputPeriod === 'Últimos 12 meses') {
      setValuesInLastTwelveMonths();
    } else if (valueInputPeriod === 'Todos') {
      setValuesAllPeriodSelected();
    } else {
      resetPeriodValues();
    }
    setOpenModalPeriodo(false);
  };

  const handleSetFilter = (newValue, inputName) => {
    setListFilter((prev) =>
      prev.map((item) =>
        item.inputName === inputName ? { ...item, value: newValue } : item
      )
    );
  };

  useEffect(() => {
    updatePayload();
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected, period]);

  useEffect(() => {
    if (period.periodo_inicio && period.periodo_fim) {
      updatePayload();
    }
  }, [period]);

  const updatePayload = () => {
    setPayload({
      params: {
        estados: getEstadosParam(),
        municipios: getMunicipiosParam(),
        estabelecimentos: getEstabelecimentosParam(),
        equipe:
          equipesSelected.length > 0 ? equipesSelected.join(', ') : undefined,
        periodo_inicio: period?.periodo_inicio,
        periodo_fim: period?.periodo_fim,
        tipo: 'Reflexo',
        page: payload.params.page || 1,
        size: payload.params.size || 25,
      },
    });
  };

  const getEstadosParam = () => {
    if (isPadrao || isMunicipal || isEstadual) return 'PE';
    return estadosSelected.length > 0
      ? JSON.stringify(estadosSelected)
      : undefined;
  };

  const getMunicipiosParam = () => {
    if (isPadrao || isMunicipal) return userToken.endereco.municipio_id;
    return municipiosSelected.length > 0
      ? municipiosSelected.join(', ')
      : undefined;
  };

  const getEstabelecimentosParam = () => {
    if (isPadrao) return userToken.estabelecimentoId;
    return estabelecimentosSelected.length > 0
      ? estabelecimentosSelected.join(', ')
      : undefined;
  };

  useEffect(() => {
    const loadEstados = async () => {
      const pernambuco = {
        id: 13,
        created_at: '2023-03-21T00:36:08.490Z',
        updated_at: '2023-03-21T00:36:08.490Z',
        nome: 'Pernambuco',
        sigla: 'PE',
        codigo: '26',
      };
      setEstadosOptions([pernambuco]);
      setAllEstadosOptions([pernambuco]);
    };

    loadEstados();
  }, []);

  useEffect(() => {
    const getOptionsCidades = async () => {
      if (estadosSelected.length === 1) {
        const optionsCidades = await getCidades(estadosSelected[0]);
        setOptionsMunicipios(optionsCidades);
        setAllMunicipiosOptions(optionsCidades);
      } else if (estadosSelected.length === 0) {
        // setOptionsMunicipios([]);
      }
    };

    getOptionsCidades();
  }, []);

  useEffect(() => {
    const getOptionsEstabelecimentos = async () => {
      if (municipiosSelected.length === 1) {
        try {
          const response = await api.get(
            '/dashboard/exames/citologia/panel/estabelecimentos',
            {
              params: {
                estados:
                  estadosSelected.length > 0
                    ? JSON.stringify(estadosSelected)
                    : undefined,
                municipios: municipiosSelected.join(', '),
              },
            }
          );

          console.log('Estabelecimentos carregados:', response.data);

          const semVinculacao = {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          };

          setOptionsEstabelecimentos([semVinculacao, ...response.data]);
          setAllEstabelecimentosOptions([semVinculacao, ...response.data]);

          if (isPadrao) {
            setEstabelecimentosSelected([userToken.estabelecimentoId]);
          }
        } catch (error) {
          console.error('Error fetching estabelecimentos:', error);
        }
      } else if (municipiosSelected.length === 0 && !isPadrao) {
        setOptionsEstabelecimentos([]);
      }
    };

    getOptionsEstabelecimentos();
  }, [municipiosSelected, estadosSelected]);

  return (
    <>
      <PeriodSelectionDialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
        valuePeriodoModal={valuePeriodoModal}
        handleSelectPeriodModal={handleSelectPeriodModal}
        valueInputPeriod={valueInputPeriod}
        year={year}
        handleChangeWhenYearSelected={handleChangeWhenYearSelected}
        valueInputQuadrimestreSemestre={valueInputQuadrimestreSemestre}
        handleChangeSemestreOrQuadrimestre={handleChangeSemestreOrQuadrimestre}
        handleCloseModal={handleCloseModal}
      />
      <Grid container spacing={2}>
        <MultipleFilters
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          mdEstado={2.6}
          mdMunicipio={2.6}
          mdEstabelecimento={2.6}
          mdEquipe={2}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />
        <Grid item xs={12} md={2}>
          <Controller
            name="periodo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledAsteriskTextField
                data-testid="periodoInput"
                fullWidth
                size="small"
                label="Período"
                value={valueInputPeriod || ''}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() => setOpenModalPeriodo(true)}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            variant="contained"
            data-testid="submitFilterPanelCitologia"
            onClick={() => {
              getAllCharts({ cleared: false, payload: payload });
              setShowListFilter(listFilter);
              setPeriodToDisplay(valueInputPeriod);
            }}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} lg={1}>
          <Button
            data-testid="clearFilterPanelCitologia"
            variant="outlined"
            color="error"
            onClick={handleClearFilter}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const PeriodSelectionDialog = ({
  open,
  onClose,
  valuePeriodoModal,
  handleSelectPeriodModal,
  valueInputPeriod,
  year,
  handleChangeWhenYearSelected,
  valueInputQuadrimestreSemestre,
  handleChangeSemestreOrQuadrimestre,
  handleCloseModal,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <Typography variant="h6" fontWeight="bold">
        Tipo de Período
      </Typography>
    </DialogTitle>
    <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
      <Paper
        variant="outlined"
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Selecione o Período para Visualização dos Dados
        </Typography>
        <Autocomplete
          placeholder="Tipo de Período"
          value={valuePeriodoModal}
          onChange={(_, newValue) => handleSelectPeriodModal(newValue)}
          options={PERIOD_OPTIONS}
          getOptionLabel={(option) => option.label ?? ''}
          renderInput={(params) => (
            <StyledAsteriskTextField
              {...params}
              fullWidth
              label="Tipo de Período"
              variant="outlined"
            />
          )}
        />
        {valueInputPeriod &&
          valueInputPeriod !== 'Últimos 12 meses' &&
          valueInputPeriod !== 'Todos' && (
            <Autocomplete
              options={YEAR_OPTIONS}
              onChange={(_, newValue) => handleChangeWhenYearSelected(newValue)}
              value={year || ''}
              renderInput={(params) => (
                <TextField {...params} label="Selecione o Ano" />
              )}
            />
          )}
        {(valueInputPeriod === 'Quadrimestral' ||
          valueInputPeriod === 'Semestral') && (
          <Autocomplete
            options={
              valueInputPeriod === 'Quadrimestral'
                ? QUADRIMESTER_OPTIONS
                : SEMESTER_OPTIONS
            }
            value={valueInputQuadrimestreSemestre || ''}
            onChange={(_, newValue) =>
              handleChangeSemestreOrQuadrimestre(newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  valueInputPeriod === 'Quadrimestral'
                    ? 'Selecione o Quadrimestre'
                    : 'Selecione o Semestre'
                }
              />
            )}
          />
        )}
      </Paper>
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        color="error"
        onClick={handleCloseModal}
        startIcon={<ClearIcon />}
      >
        CANCELAR
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={onClose}
        endIcon={<CheckIcon />}
      >
        CONFIRMAR
      </Button>
    </DialogActions>
  </Dialog>
);

export default FiltersVisaoGeral;
