import { ApexOptions } from 'apexcharts';
import { useEffect, useRef, useState } from 'react';
import { PieChart } from '../pieChart';
import './RealizationProcedurePosNotRealizedStyles.scss';
import ItemGraphic from './ItemGraphic';
import EmptyData from '../../../../../components/Charts/emptyData';

interface ReasonsNotProcedureChartProps {
  data: number[];
  categories?: String[];
  height?: number;
  payload: any;
}

export function RealizationProcedurePosNotRealized({
  data,
  payload,
}: ReasonsNotProcedureChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  const emptyData = Object.keys(data).length === 0;

  useEffect(() => {
    setChartData({
      series: data,
      chart: {
        width: 284,
        type: 'pie',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (config.dataPointIndex > -1) {
              localStorage.removeItem('result');
              localStorage.removeItem('typeVirus');
              localStorage.removeItem('collectionOrigin');

              const values = {
                0: 'NAO_REALIZOU',
                1: 'MENSTRUADA',
                2: 'NAO_FAIXA_ETARIA',
                3: 'HISTERECTOMIZADA',
                4: 'OUTRO',
              };

              localStorage.setItem(
                'reasonNotProcedure',
                values[config.dataPointIndex]
              );
              localStorage.setItem('requestFromTable', 'true');
              localStorage.setItem('payload', JSON.stringify(payload));
              window.location.href = '/relatorios/hpv';
            }
          },
        },
      },
      labels: [
        `Compareceu mas não realizou o teste (${data[0]})`,
        `Estava Menstruada (${data[1]})`,
        `Não está na faixa etária(${data[2]})`,
        `Histerectomizada por patologia benigna (${data[3]})`,
        `Outros (${data[4]})`,
      ],
      colors: ['#64b6f7', '#2196F3', '#0B79D0', '#1769ab', '#ACB7B8'],
      yaxis: {
        min: 0,
      },
      xaxis: {
        min: 0,
      },
      legend: {
        position: 'right',
        width: 200,
        offsetY: 40,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            legend: {
              position: 'bottom',
              horizontalAlign: 'left',
              width: 300,
              offsetY: 0,
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      plotOptions: {
        pie: {
          startAngle: -135,
          endAngle: 225,
        },
      },
    });
  }, [data]);

  const [elementUnderMouse, setElementUnderMouse] = useState<any>();
  const [disableGraphicsOnHover, setDisableGraphicsOnHover] = useState<any>();

  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    const element = document.elementFromPoint(x, y);

    setElementUnderMouse(element);
  };

  const refRealized = useRef(null);
  const refNotRealized = useRef(null);

  useEffect(() => {
    switch (elementUnderMouse) {
      case refRealized.current:
        setDisableGraphicsOnHover(1);
        break;

      case refNotRealized.current:
        setDisableGraphicsOnHover(2);
        break;
      default:
        setDisableGraphicsOnHover(0);
        break;
    }
  }, [elementUnderMouse]);

  return (
    <>
      {chartData ? (
        <>
          <div style={{ display: 'none' }}>
            <ul>
              {/* {data.map((value, index) => {
                const total = data[0] + data[1] + data[2] + data[3] + data[4];
                const percentNaoRealizou = `${Number(
                  (data[0] / total) * 100
                ).toFixed(1)}%`;
                const percentMenstruada = `${Number(
                  (data[1] / total) * 100
                ).toFixed(1)}%`;
                const percentNotAge = `${Number(
                  (data[2] / total) * 100
                ).toFixed(1)}%`;
                const percentHisterectomizada = `${Number(
                  (data[3] / total) * 100
                ).toFixed(1)}%`;
                const percentOthers = `${Number(
                  (data[4] / total) * 100
                ).toFixed(1)}%`;
                return (
                  <>
                    {index === 0 && (
                      <li>
                        <p data-testid="labelAndValueNaoRealizou">
                          Compareceu mas não realizou o teste ({value})
                        </p>
                        <p data-testid="percentNaoRealizou">
                          percentNaoRealizou: {percentNaoRealizou}
                        </p>
                      </li>
                    )}
                    {index === 1 && (
                      <li>
                        <p data-testid="labelAndValueMenstruada">
                          Estava Menstruada ({value})
                        </p>
                        <p data-testid="percentMenstruada">
                          percentMenstruada: {percentMenstruada}
                        </p>
                      </li>
                    )}
                    {index === 2 && (
                      <li>
                        <p data-testid="labelAndValueNotAge">
                          Não está na faixa etária ({value})
                        </p>
                        <p data-testid="percentNotAge">
                          percentNotAge: {percentNotAge}
                        </p>
                      </li>
                    )}
                    {index === 3 && (
                      <li>
                        <p data-testid="labelAndValueHisterectomizada">
                          Histerctomizada por patologia benigna ({value})
                        </p>
                        <p data-testid="percentHisterectomizada">
                          percentHisterectomizada: {percentHisterectomizada}
                        </p>
                      </li>
                    )}
                    {index === 4 && (
                      <li>
                        <p data-testid="labelAndValueOthers">
                          Outros ({value})
                        </p>
                        <p data-testid="percentOthers">
                          percentOthers: {percentOthers}
                        </p>
                      </li>
                    )}
                  </>
                );
              })} */}
            </ul>
          </div>

          <div className="boxData" onMouseMove={handleMouseMove}>
            <div className="boxData__legends">
              <div
                className="boxData__legends__colorizedLegend"
                style={{
                  backgroundColor: '#2DC4CC',
                }}
              />
              <span ref={refRealized}>Realizaram</span>
              <div
                className="boxData__legends__colorizedLegend"
                style={{
                  backgroundColor: '#FFB547',
                  marginLeft: '12px',
                }}
              />
              <span ref={refNotRealized}>Não Realizaram</span>
            </div>

            {emptyData ? (
              <div style={{ height: '357px', marginTop: '-128px' }}>
                <EmptyData />
              </div>
            ) : (
              <>
                <div className="boxData__content">
                  <ItemGraphic
                    value1={data['NAO_REALIZOU']?.realizado}
                    value2={data['NAO_REALIZOU']?.nao_realizado}
                    label="Compareceu mas não realizou o teste"
                    disableGraphicsOnHover={disableGraphicsOnHover}
                  />
                  <ItemGraphic
                    value1={data['MENSTRUADA']?.realizado}
                    value2={data['MENSTRUADA']?.nao_realizado}
                    label="Estava Menstruada"
                    disableGraphicsOnHover={disableGraphicsOnHover}
                  />
                  <ItemGraphic
                    value1={data['NAO_FAIXA_ETARIA']?.realizado}
                    value2={data['NAO_REALIZOU']?.nao_realizado}
                    label="Não está na faixa"
                    disableGraphicsOnHover={disableGraphicsOnHover}
                  />
                  <ItemGraphic
                    value1={data['HISTERECTOMIZADA']?.realizado}
                    value2={data['HISTERECTOMIZADA']?.nao_realizado}
                    label="Histerectomizada por patologia benigna"
                    disableGraphicsOnHover={disableGraphicsOnHover}
                  />
                  <ItemGraphic
                    value1={data['OUTRO']?.realizado}
                    value2={data['OUTRO']?.nao_realizado}
                    label="Outro"
                    disableGraphicsOnHover={disableGraphicsOnHover}
                    removeBorder
                  />
                </div>
              </>
            )}
          </div>
        </>
      ) : undefined}
    </>
  );
}
