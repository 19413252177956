import React from 'react';
import { Box, Typography, Paper, useMediaQuery, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import styles from './styles.module.css';
import { DataSource } from '../../../hpv/components/dataSource';

interface DataRow {
  label: string;
  hpv_outros_count?: number;
  citologia_realizar?: number;
  citologia_count?: number;
}

interface TableResultsProps {
  data: DataRow[];
  totalItems: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  page: number;
  rowsPerPage: number;
  valueInputPeriod?: string;
  total: {
    hpv: number;
    citologia: number;
    realizar: number;
  };
}

function Card({ row }: { row: DataRow }) {
  return (
    <Box py="16px" className={styles.cardTableMobile}>
      <Typography fontSize="14px" fontWeight="bold">
        Município
      </Typography>
      <Typography fontSize="14px">{row.label}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Mulheres com HPV Outros
      </Typography>
      <Typography fontSize="14px">{row.hpv_outros_count || 0}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Citologias Realizadas
      </Typography>
      <Typography fontSize="14px">{row.citologia_count || 0}</Typography>

      <Typography fontSize="14px" fontWeight="bold">
        Citologias a Realizar
      </Typography>
      <Typography fontSize="14px">{row.citologia_realizar || 0}</Typography>
    </Box>
  );
}

export function TableResults({
  data,
  totalItems,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  valueInputPeriod,
  total,
}: TableResultsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <div className={styles.container}>
      <Typography variant="h5" fontWeight="bold" fontSize="20px">
        Exames de Citologia - Reflexo (após teste de HPV Positivo)
        <br />
        Mulheres com HPV Outros x Citologias Realizadas
      </Typography>
      <Box sx={{ margin: '8px 0', fontSize: '12px', color: '#9E9E9E' }}>
        {valueInputPeriod === 'Todos' || !valueInputPeriod
          ? 'Todos os períodos'
          : valueInputPeriod}
      </Box>

      <Paper sx={{ width: '100%', mb: 2, pt: 2 }}>
        {isMobile ? (
          <>
            {data.map((row, index) => (
              <Card key={index} row={row} />
            ))}
            <Card
              row={{
                label: 'Total',
                hpv_outros_count: total.hpv,
                citologia_count: total.citologia,
                citologia_realizar: total.realizar,
              }}
            />
          </>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Município
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Mulheres com HPV Outros
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Citologias Realizadas
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Citologias a Realizar
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell>{row.label}</TableCell>
                    <TableCell>{row.hpv_outros_count || 0}</TableCell>
                    <TableCell>{row.citologia_count || 0}</TableCell>
                    <TableCell>{row.citologia_realizar || 0}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    Total
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {total.hpv}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {total.citologia}
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    {total.realizar}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
          labelRowsPerPage={'Itens por página'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `mais do que ${to}`
            }`;
          }}
          nextIconButtonProps={{ 'aria-label': 'Próxima página' }}
          backIconButtonProps={{ 'aria-label': 'Página anterior' }}
          showFirstButton={false}
          showLastButton={false}
        />
      </Paper>
      <br />
      <DataSource />
    </div>
  );
}
