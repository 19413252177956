import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { TabPanelProps } from '../../../components/BoxInfoPatient/BoxInfoPatient';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { getEstados } from '../../../lib/getEstados';
import { getWindowDimensions } from '../../../utils/getWindowDimensions';
import { VisaoGeral } from './tabs/VisaoGeral';
import { Indicadores } from './tabs/Indicadores';

interface Estado {
  id: string | number;
  nome: string;
  sigla: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ColposcopiaPanel = () => {
  const [loading, setLoading] = React.useState(false);
  const [estados, setEstados] = React.useState<Estado[]>([]);
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [tabSize, setTabSize] = React.useState('33%');
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const loadEstados = async () => {
    const response = await getEstados();
    setEstados(response);
  };

  // React.useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener('resize', handleResize);

  //   if (windowDimensions.width < 768) {
  //     setTabSize('100%');
  //   } else {
  //     setTabSize('33%');
  //   }

  //   return () => window.removeEventListener('resize', handleResize);
  // }, [windowDimensions]);

  React.useEffect(() => {
    loadEstados();
  }, []);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h4" fontWeight="bold" data-testid="panel">
                  Painel do Teste de Colposcopia
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                  sx={{ marginBottom: '24px' }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/home"
                  >
                    Home
                  </Link>
                  <Typography key="3" color="text.primary">
                    Painel do Teste de Colposcopia
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>

            <Divider />

            {/* <Box
              sx={{
                borderBottom: 2,
                borderColor: '#238884',
                marginTop: '25px',
              }}
            >
              <Tabs
                orientation={
                  window.innerWidth < 768 ? 'vertical' : 'horizontal'
                }
                variant="scrollable"
                sx={{
                  backgroundColor: '#ffffff',
                  color: '#ffffff',

                  '& .MuiTab-root': {
                    color: '#238884',
                    maxWidth: `${window.innerWidth < 768 ? '100%' : '33%'}`,
                  },

                  '&& .Mui-selected': {
                    backgroundColor: '#238884',
                    color: '#FFFFFF',
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#238884',
                    color: '#FFFFFF',
                  },
                }}
                value={value}
                onChange={handleChange}
              >
                <Tab
                  sx={{ width: tabSize }}
                  label="VISÃO GERAL"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ width: tabSize }}
                  label="INDICADORES"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ width: tabSize }}
                  disabled
                  label="ACOMPANHAMENTO"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box> */}
            <VisaoGeral />
            {/* <TabPanel value={value} index={0}>
              <VisaoGeral />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Indicadores />
            </TabPanel>
            <TabPanel value={value} index={2}>
              Acompanhamento
            </TabPanel> */}
          </Box>
        </Container>
      </SideMenu>
    );
  }
};
