import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import CustomTablePagination from '../../../../../components/CustomTablePagination';

interface DataItem {
  municipio: string;
  normais: number;
  anormais: number;
}

interface TipoAchadosStackedBarsChartProps {
  data: DataItem[];
}

const TipoAchadosStackedBarsChart: React.FC<TipoAchadosStackedBarsChartProps> = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '26px',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val as number) + '%'; // Mostra a porcentagem como um número inteiro
      },
      style: {
        fontSize: '16px', // Tamanho ajustado
        fontWeight: 500, // Medium
        colors: ['#fff'],
      },
    },
    xaxis: {
      categories: paginatedData.map((item) => item.municipio),
      labels: {
        show: true,
        formatter: function (val) {
          return Math.round(val as unknown as number) + '%'; // Mostra como inteiro
        },
        style: {
          fontSize: '16px', // Tamanho ajustado
          fontWeight: 500, // Medium
          colors: ['#757575'], // Cor ajustada
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '16px', // Tamanho ajustado
          fontWeight: 500, // Medium
          colors: ['#757575'], // Cor ajustada
        },
      },
      tickAmount: 4,
    },
    legend: {
      position: 'top', // Coloca a legenda acima do gráfico
      horizontalAlign: 'center', // Centraliza a legenda
      fontSize: '16px', // Tamanho ajustado
      fontWeight: 500, // Medium
      fontFamily: 'Roboto, sans-serif',
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
      },
      labels: {
        colors: ['#000000'], // Cor ajustada
      },
    },
    colors: ['#25A2A8', '#C77700'],
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: 'Achado Colposcópico Normal',
      data: paginatedData.map((item) => item.normais),
    },
    {
      name: 'Achado Colposcópico Anormal',
      data: paginatedData.map((item) => item.anormais),
    },
  ];

  return (
    <Box>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={paginatedData.length * 50 + 100}
      />
      <CustomTablePagination
  rowsPerPageOptions={[5, 10, 25]}
  component="div"
  count={data.length}
  page={page}
  setPage={setPage} // Certifique-se de passar corretamente o setPage
  onPageChange={handleChangePage}
  rowsPerPage={rowsPerPage}
  onRowsPerPageChange={handleChangeRowsPerPage}
  labelRowsPerPage={'Itens por página'}
  labelDisplayedRows={({ from, to, count }) => {
    return `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`;
  }}
/>

    </Box>
  );
};

export default TipoAchadosStackedBarsChart;
