import { ApexOptions } from 'apexcharts';
import { set } from 'date-fns';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

interface FunnelChartProps {
  data: { x: string; y: number }[];
}

export function FunnelChart({ data }: FunnelChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  useEffect(() => {
    if (data.length === 0) {
      setChartData(undefined);
      return;
    }

    const allDataZero = data.every(item => item.y === 0);
    if (allDataZero) {
      setChartData(undefined);
      return;
    }


    const sortedData = [...data].sort((a, b) => b.y - a.y);

    setChartData({
      series: [
        {
          name: "Quantidade de lesões: ",
          data: sortedData.map((item) => item.y), 
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true, 
          barHeight: '62%', 
          isFunnel: true, 
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          const label = sortedData[opt.dataPointIndex].x;
          return `${label}: ${val}`;
        },
        dropShadow: {
          enabled: false,
        },
        style: {
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 'bold',
          fontSize: '16px', 
          colors: ['#FFFFFF'], 
        },
      },
      fill: {
        colors: ['#283371'], 
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#7986CB'], 
      },
      xaxis: {
        categories: sortedData.map((item) => item.x), 
      },
      grid: {
        padding: {
          left: 16,
          right: 16,
        },
      },
      legend: {
        show: false, 
      },
    });
  }, [data]);

  if (!chartData) {
    return <div style={{ height: 500 }} />;
  }
    return (
    <div style={{ padding: '0 16px 22px 16px' }}>
      {/* Gráfico */}
      <Chart
        options={chartData}
        series={chartData.series}
        type="bar" 
        width="100%"
        height={500}
      />
    </div>
  );
}
