import React from 'react';
import { Card, Typography, Box } from "@mui/material";
import { styled } from '@mui/system';

interface CardRealizarBiopsiaProps {
  title: string;
  period: string;
  color: string;
  number: number;
  percentage: number;
}

const StyledCard = styled(Card)<{ bgColor: string }>(({ theme, bgColor }) => ({
  width: '100%',
  height: '50%',
  padding: '12px 16px 16px 24px',
  backgroundColor: bgColor,
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '12px',
  position: 'relative',
  overflow: 'hidden',
  border: '1px solid rgba(0, 0, 0, 0.23)',
}));

const ColorStrip = styled(Box)<{ stripColor: string }>(({ stripColor }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '8px',
  height: '100%',
  backgroundColor: stripColor,
}));

const CardRealizarBiopsia: React.FC<CardRealizarBiopsiaProps> = ({
  title,
  period,
  color,
  number,
  percentage,
}) => {
  return (
    <StyledCard bgColor={color}>
      <ColorStrip stripColor={color} />
      
      <Box>
        <Typography variant="h6" component="h2" color="white" fontWeight={700} mb={0.5}>
          {title}
        </Typography>
        <Typography variant="caption" color="white">
          {period}
        </Typography>
      </Box>

      <Typography variant="h3" component="div" color="white" fontWeight={700} textAlign="center">
        {number} - {percentage}%
      </Typography>

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="caption" color="white">
          Fonte dos Dados: JORDANA
        </Typography>
      </Box>
    </StyledCard>
  );
};

export default CardRealizarBiopsia;