import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import EmptyData from '../emptyData';

interface BarChartProps {
  data: number[];
  categories?: string[] | string[][];
  height?: number;
  colors: string[];
  onClick?: (event: any, chartContext: any, config: any) => void;
}

export function BarChart({ data, categories, colors, onClick }: BarChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();

  const emptyData = data.filter((item) => item > 0).length === 0;

  useEffect(() => {
    setChartData({
      series: [
        {
          name: '',
          data: data,
        },
      ],
      chart: {
        type: 'bar',
        height: 284,
        toolbar: {
          tools: {
            download: false,
          },
        },
        events: {
          dataPointSelection: onClick,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['rgba(0,0,0,0.7)'],
        },
        background: {
          enabled: true,
          foreColor: '#ffffff',
          borderRadius: 6,
          borderColor: 'transparent',
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        type: 'category',
        categories,
        labels: {
          rotate: 0,
        },
      },
      yaxis: {
        min: 0,
      },
      fill: {
        opacity: 1,
      },
      colors,
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
            },
          },
        },
      ],
    });
  }, [data, categories, colors, onClick]);

  return (
    <>
      {chartData ? (
        <>
          <div style={{ display: 'none' }}>
            <ul>
              <li data-testid="twentyFiveToThirtyFourYearsTested">{data[0]}</li>
              <li data-testid="thirtyFiveToFourtyFiveYearsTested">{data[1]}</li>
              <li data-testid="FourtySixToSixtyFourYearsTested">{data[2]}</li>
              <li data-testid="outRangeTested">{data[3]}</li>
            </ul>
          </div>
          <div style={{ position: 'relative' }}>
            {emptyData ? (
              <div style={{ position: 'absolute', zIndex: '1', left: '30%' }}>
                <EmptyData />
              </div>
            ) : undefined}
            <ReactApexChart
              options={chartData}
              series={chartData.series}
              type="bar"
              height={284}
            />
          </div>
        </>
      ) : undefined}
    </>
  );
}
